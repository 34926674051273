<template>
	<div>
		<p v-if="productView.size && Object.keys(productView.size).length > 0" class="content-product-size-title">
			{{ $t('shopping_cart.size') }}: <span> {{productView.size.name}} </span>
		</p>
        <div v-if="product.group && Object.keys(product.group).length > 0" 
        	class="content-product-size-elements">
        	<template v-for="(productSize, pIndex) in product.group.products">
		        <a           			          	
		          	:key="pIndex" 
		          	:class="{active: (productSize.id == productView.id)}" 
		          	@click="setProductView(productSize)"> {{productSize.size.name}} </a>
		    </template>
        </div>              
    </div>
</template>
<script>    
	import {mapGetters, mapActions} from 'vuex';

	export default{
        computed: {
			...mapGetters('Product', ['product', 'productView', 'loading']),
			...mapGetters('Cart', ['cartPayload']),			
		},
		methods: {
			...mapActions('Product', ['setProductView']),
		}
	}
</script>