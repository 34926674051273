<template>
	<div class="col-lg-6 mb-5 mb-lg-0">
      <!-- swiper1 -->
      <swiper
        class="swiper gallery-top"
        :options="swiperOptionTop"
        ref="swiperTop"
      >
      <template v-if="loading">
        <swiper-slide>
          <PuSkeleton :count="10" style="height: 30px;"></PuSkeleton>
        </swiper-slide>
      </template>
      <template v-else v-for="(image, imageIndex) in productView.attachs">
          <swiper-slide v-if="image" :key="imageIndex">
          <img
            class="img-fluid"
            v-lazy="image"
            alt="image product"
            title="image product"
          />
        </swiper-slide>
      </template>
        <!-- <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div> -->
      </swiper>
      <!-- swiper2 thumbs -->
      <swiper
        class="swiper gallery-thumbs"
        :options="swiperOptionThumbs"
        ref="swiperThumbs"
      >
        <template v-for="(image, imageIndex) in productView.attachs">
          <swiper-slide v-if="image" :key="imageIndex">
            <div class="gallery-thumbs-image">
              <img
                class="img-fluid"
                v-lazy="image"
                alt="image product miniatura"
                title="image product miniatura"
              />
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import {mapGetters} from 'vuex';

export default {
  name: "SwiperImages",
  components: {
    Swiper,
    SwiperSlide,
  },
	computed: {
		...mapGetters('Product', ['productView', 'loading']),	
	},
  data() {
    return {
      swiperOptionTop: {
        notNextTick: true,
        // loopedSlides: 5,
        // loop: false,
        spaceBetween: 10,
        speed: 800,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        notNextTick: true,
        // loopedSlides: 5,
        spaceBetween: 10,
        // loop: false,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
        speed: 800,
      },      
    };
  },
  methods: {
  },
  mounted() {
    const swiperTop = this.$refs.swiperTop.$swiper;
    const swiperThumbs = this.$refs.swiperThumbs.$swiper;
    swiperTop.controller.control = swiperThumbs;
    swiperThumbs.controller.control = swiperTop;
  },
  // updated () {
  //   if (this.isInit === 1) {
  //     this.$nextTick(() => {
  //       const swiperTop = this.$refs.swiperTop.$swiper;
  //       const swiperThumbs = this.$refs.swiperThumbs.$swiper;
  //       swiperTop.controller.control = swiperThumbs;
  //       swiperThumbs.controller.control = swiperTop;
  //     })
  //     this.isInit = 0
  //   }
  // },
};
</script>