<template>
	<div class="content-product-size">
        <template v-if="loading && Object.keys(productView).length == 0">
            <PuSkeleton :count="3"></PuSkeleton>
        </template>
        <template v-else>            
            <variant-size></variant-size>
        </template>
    </div>
</template>
<script>
    import VariantSize from './VariantSize';    
	import {mapGetters} from 'vuex';

	export default{
        components: {
            VariantSize,            
        },
		computed: {
			...mapGetters('Product', ['productView', 'loading'])
		}
	}
</script>